const token = () => {
  const el = document.querySelector('meta[name=csrf-token]');
  if (el !== null) {
    return el.getAttribute('content');
  }
  return null;
};

export default {
  token,
};
