<template>
  <div>
    <button @click="recordToggle">
      {{ firstButtonText }}
    </button>
    <audio
      v-if="displayRecorded"
      id="recorded"
      controls=""
      autoplay=""
      :src="audioSource"
    />
    <button
      v-if="showUpload"
      @click="upload"
    >
      Upload (i18n)
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import csrf from '../utils/csrf';
import flash from '../utils/flash';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    recordedtext: {
      type: String,
      required: true,
    },
    stoptext: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    resourceid: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      chunks: [],
      stream: null,
      recorder: null,
      displayUploader: true,
      recording: false,
      displayRecorded: false,
      audioSource: null,
      firstButtonText: `${this.$props.recordtext}`,
      blob: null,
      showUpload: false,
    };
  },

  methods: {
    filename() {
      return `${Date.now()}-${this.$props.resource}.wav`;
    },

    upload($event) {
      if ($event) {
        $event.preventDefault();
      }

      const formdata = new FormData();
      formdata.append('recording', new File([this.blob], this.filename(), { type: 'audio/wav' }));

      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token();
      axios({
        method: 'PATCH',
        url: `/${this.$props.resource}/${this.$props.resourceid}/upload`,
        data: formdata,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        flash.info('File uploaded (i18n)');
        this.showUpload = false;

        // TODO: pass the ID somehow, and keep the recorded one displayed
        document.getElementById('recording-preview').style.display = 'none';
      }).catch(() => {
        flash.error('Internal error');
      });
    },

    recordToggle($event) {
      if ($event) {
        $event.preventDefault();
      }

      this.displayUploader = false;

      if (this.recording) {
        this.stop();
      } else {
        this.record();
      }
    },

    record() {
      this.showUpload = false;
      this.chunks = [];
      this.displayRecorded = false;

      // TODO: constraints
      // TODO: check navigator.mediaDevices on mount
      // TODO: catch promise error when user refuses to accept mike control
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        try {
          this.recorder = new MediaRecorder(stream);
        } catch (e) {
          // TODO
          // console.error('Exception while creating MediaRecorder:', e);
          return;
        }

        // TODO: do I need to event for something (ellided parameter)
        this.recorder.onstop = () => {
          this.recording = false;
          this.firstButtonText = this.$props.recordtext;
          this.displayRecorded = true;

          // Encode the blob and shove it into our test audio element.
          this.blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' });
          this.chunks = [];
          const audioURL = URL.createObjectURL(this.blob);
          this.audioSource = audioURL;

          // Stop all tracks
          stream.getTracks().forEach((track) => track.stop());
        };

        this.recorder.ondataavailable = this.dataAvailable;

        this.recording = true;
        this.firstButtonText = this.$props.stoptext;
        this.recorder.start();
      });
    },

    dataAvailable(event) {
      if (event.data && event.data.size > 0) {
        this.chunks.push(event.data);
      }
    },

    stop() {
      this.showUpload = true;
      this.recorder.stop();
    },
  },
};
</script>
