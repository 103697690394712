// TODO: this whole file is latin-specific. I should come up with something more
// general...

const NOMINATIVE = 0;
const VOCATIVE = 1;
const ACCUSATIVE = 2;
const GENITIVE = 3;
const DATIVE = 4;
const LOCATIVE = 6;

const IMPERATIVE = 2;

const SINGULAR = 0;
const PLURAL = 1;

const MASCULINE = 0;
const FEMININE = 1;
const NEUTER = 2;

const NOUN = 1;
const ADJECTIVE = 2;
const VERB = 3;
const PRONOUN = 4;

const ONLY_SINGULAR = 1;
const CONTRACTED_ROOT = 2;
const ONLY_PLURAL = 3;

let forms = {};

const addForms = (f) => {
  forms = { ...forms, ...f };
};

const isNomVocSing = (casus, number) => {
  if (number !== SINGULAR) {
    return false;
  }
  return casus === NOMINATIVE || casus === VOCATIVE;
};

// NOTE that this function already assumes that the given word is defective in
// the sense that the root should be contracted.
const shouldContractRoot = (word, casus, number) => {
  if (word.kind === 'er/ir' && (word.category === NOUN || word.category === ADJECTIVE)) {
    return !isNomVocSing(casus, number);
  }
  if (word.category === ADJECTIVE) {
    return true;
  }
  return false;
};

const shouldRemoveLastChar = (word, casus, number) => {
  if (word.kind === 'ius' && word.category === NOUN) {
    return casus === VOCATIVE && number === SINGULAR;
  }
  return false;
};

const shouldLeaveFirstEnunciated = (word, casus, number) => {
  if (number === PLURAL) {
    return false;
  }

  if (casus === NOMINATIVE || casus === VOCATIVE) {
    return word.kind === 'is' || word.kind === 'istem' || word.kind === 'pureistem'
      || word.kind === 'isneuter' || word.kind === 'istemneuter' || word.kind === 'pureistemneuter';
  } if (casus === ACCUSATIVE) {
    return word.kind === 'isneuter' || word.kind === 'istemneuter'
      || word.kind === 'pureistemneuter';
  }
  return false;
};

const inflectRoot = (word, casus, number) => {
  if (typeof (word.regular) !== 'undefined' && !word.regular) {
    // Irregular adjectives are not *so* irregular. Instead, they only change a
    // couple of things that can be handled on their own, and they don't even
    // inflect the root of the word in any meaningful way. Thus, only irregular
    // non-adjectives should return an empty string.
    if (word.category !== ADJECTIVE) {
      return '';
    }
  } else if (word.defective === CONTRACTED_ROOT && shouldContractRoot(word, casus, number)) {
    // TODO: does this even work for non-unicode chars? (e.g. long vowel
    // before the consonant)
    let { particle } = word;
    particle = particle.slice(0, particle.length - 2);
    particle += word.particle.slice(-1);
    return particle;
  } else if (shouldRemoveLastChar(word, casus, number)) {
    const { particle } = word;
    return particle.slice(0, particle.length - 1);
  } else if (shouldLeaveFirstEnunciated(word, casus, number)) {
    if (typeof (word.enunciated) !== 'undefined') {
      return word.enunciated.split(',')[0].trim();
    }
  }
  return word.particle;
};

const inflectTerm = (word, casus, number, gender) => {
  const res = { term: '', id: null };

  // Handle special cases now.
  if (word.kind === 'er/ir') {
    // Nominative/vocative singular for -er words (e.g. puer)
    if (isNomVocSing(casus, number)) {
      return '';
    }
  }

  // The items might come with a gender differentiator or not.
  let items;
  if (gender == null || typeof (gender) === 'undefined') {
    items = forms[word.kind];
  } else {
    items = forms[`${word.kind}-${gender}`];
  }

  if (typeof (items) === 'undefined') {
    return res;
  }

  // Handle irregularities now.
  if (!word.regular) {
    // The first and second declensions of adjectives can have some
    // irregularities (ūnus nauta). The only difference is on the genitive and
    // dative singulars.
    // TODO: check whether this id field is relevant in this case.
    if (number === SINGULAR && word.category === ADJECTIVE
        && (word.kind === 'us' || word.kind === 'er/ir')) {
      if (casus === GENITIVE) {
        res.term = 'īus';
      } else if (casus === DATIVE) {
        res.term = 'ī';
      }
      return res;
    }
  }

  for (let i = 0; i < items.length; i += 1) {
    if (items[i].case === casus && items[i].number === number) {
      res.id = items[i].id;
      res.term = items[i].value;
      break;
    }
  }

  return res;
};

const inflect = (word, casus, number, opts) => {
  // Some words are defective in a way so they don't have a singular/plural
  // form. If this is the case, just return early.
  if ((number === PLURAL && word.defective === ONLY_SINGULAR)
        || (number === SINGULAR && word.defective === ONLY_PLURAL)) {
    return '';
  }

  // The locative case is pretty exceptional. If the caller requested for a
  // locative, make sure that it's actually doable.
  if (casus === LOCATIVE && !word.locative) {
    return '';
  }

  let root = inflectRoot(word, casus, number);
  const term = inflectTerm(word, casus, number, opts.gender);

  if (word.category === PRONOUN) {
    root = '';
  }

  if (typeof (opts.markTerm) !== 'undefined' && opts.markTerm) {
    return `${root}<b>${term.term}</b>`;
  }
  if (typeof (opts.addTermId) !== 'undefined' && opts.addTermId) {
    return `${term.id}-${root}${term.term}`;
  }
  return `${root}${term.term}`;
};

const reflectGender = (word, gender) => {
  if (word.category === ADJECTIVE) {
    switch (word.kind) {
      case 'us':
      case 'er/ir':
        return MASCULINE;
      case 'a':
        return FEMININE;
      case 'um':
        return NEUTER;
      default:
        return -1;
    }
  } else if (word.category === PRONOUN) {
    return gender;
  }

  return -1;
};

const conjugateFromTable = (word, table, person, number) => {
  const res = { root: word.particle, term: null, id: null };

  if (typeof (table) === 'undefined') {
    // TODO: error out
    return res;
  }

  for (let i = 0; i < table.length; i += 1) {
    if (table[i].person === person && table[i].number === number) {
      res.term = table[i];
    }
  }
  if (res.term === null) {
    // TODO: error out
    return res;
  }

  if (!word.regular) {
    res.root = '';
  }
  return res;
};

const conjugate = (word, conj, voice, mood, tense, person, number, opts) => {
  const table = forms[conj.order][tense];
  if (typeof (table) === 'undefined') {
    // TODO: error out
    return '';
  }

  const res = conjugateFromTable(word, table, person, number);
  if (res.term == null) {
    return '';
  }

  if (typeof (opts.markTerm) !== 'undefined' && opts.markTerm) {
    return `${res.root}<b>${res.term.value}</b>`;
  }
  return `${res.root}${res.term.value}`;
};

const conjugated = (res, opts) => {
  if (res.term == null) {
    return '';
  }
  if (typeof (opts.addTermId) !== 'undefined' && opts.addTermId) {
    return `${res.term.id}-${res.root}${res.term.value}`;
  }
  return `${res.root}${res.term.value}`;
};

const conjugateWithKey = (word, key, person, number, opts) => {
  const table = forms[key];
  if (typeof (table) === 'undefined') {
    // TODO: error out
    return '';
  }

  const res = conjugateFromTable(word, table, person, number, opts);
  return conjugated(res, opts);
};

const conjugateWithForm = (word, form, opts) => {
  const res = conjugateFromTable(word, [form], form.person, form.number);
  return conjugated(res, opts);
};

export default {
  inflect,
  conjugate,
  conjugateWithKey,
  conjugateWithForm,
  addForms,
  reflectGender,
  SINGULAR,
  PLURAL,
  ONLY_SINGULAR,
  ONLY_PLURAL,
  LOCATIVE,
  NOMINATIVE,
  VOCATIVE,
  NOUN,
  VERB,
  PRONOUN,
  IMPERATIVE,
};
