// t picks up the HTML element identified by the given `id` and picks its data
// by assuming that it contains a translated string. If this element does not
// exist it will return an error string.
const t = (id) => {
  const aux = document.getElementById(id);
  if (aux === null) {
    return `error: missing translation string ${id}!`;
  }

  return aux.getAttribute('data').trim();
};

export default {
  t,
};
