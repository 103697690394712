<template>
  <div
    v-if="visible"
    id="cookies"
  >
    <p>{{ message }}</p>
    <button
      class="btn btn-basic"
      @click="submit(true)"
    >
      {{ yes }}
    </button>
    <button
      class="btn btn-basic"
      @click="submit(false)"
    >
      {{ no }}
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import csrf from '../utils/csrf';
import flash from '../utils/flash';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    yes: {
      type: String,
      required: true,
    },
    no: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      visible: true,
    };
  },

  methods: {
    submit(value) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token();

      axios({
        method: 'PATCH',
        url: `/cookies/${this.$props.id}`,
        data: { accept: value },
      }).then((resp) => {
        if (resp.data.error !== '' && resp.data.error !== null) {
          flash.error(resp.data.error);
        } else if (resp.data.accepts) {
          this.visible = false;
        } else {
          document.getElementById('close-session').click();
        }
      }).catch(() => {
        flash.error('Internal error');
      });
    },
  },
};
</script>
