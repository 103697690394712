<template>
  <div class="hover:shadow-lg group block rounded-lg p-4 border border-gray-200">
    <dl class="grid sm:block lg:grid xl:block grid-cols-2 grid-rows-2 items-center">
      <div>
        <dt class="sr-only">
          Enunciate
        </dt>
        <dd class="leading-6 font-medium text-black justify-between items-center flex">
          <div>
            <span class="enunciate text-lg leading-6 font-medium text-black">{{ word.enunciated }}</span>

            <i
              v-if="word.gender.length > 0"
              class="text-xs cursor-pointer hover:underline"
              :title="word.gender[1]"
              @click="clicked(`gen:${word.gender[2]}`)"
            >{{ word.gender[0] }}</i><span
              v-if="word.gender.length > 0"
              class="text-xs"
            >; </span>

            <i
              class="text-xs cursor-pointer hover:underline"
              @click="clicked(word.declorconj[1])"
            >{{ word.declorconj[0] }}</i>
          </div>

          <button
            v-if="word.recording"
            class="border rounded-full group flex items-center bg-blue-100 px-2 py-2 hover:shadow-lg"
          >
            <i class="fas fa-volume-up" />
          </button>
        </dd>
      </div>

      <div>
        <dt class="sr-only">
          Category
        </dt>
        <dd class="text-sm font-medium sm:mb-4 lg:mb-0 xl:mb-4">
          <i
            class="text-gray text-xs hover:underline cursor-pointer"
            @click="clicked(`cat:${word.category.toLowerCase()}`)"
          >{{ word.category }}</i>

          <i class="text-gray text-xs">·</i>
          <a
            class="text-xs"
            :href="word.url[0]"
          >{{ word.url[1] }}</a>
        </dd>
      </div>

      <div>
        <dt class="sr-only">
          Content
        </dt>
        <dd>
          <p class="text-gray sm:mb-4 lg:mb-0 xl:mb-4">
            {{ this.short() }}
          </p>
        </dd>
      </div>

      <div class="col-start-2 row-start-1 row-end-3">
        <dt class="sr-only">
          Tags
        </dt>
        <dd>
          <span
            v-for="tag in word.tags"
            class="inline-block bg-blue-100 rounded-full px-3 py-1 text-xs font-semibold text-blue-900 mr-2 mb-2 cursor-pointer"
            @click="tagClicked(tag.name)"
          >{{ tag.name }}</span>
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import i18n from '../utils/i18n';

export default {
  props: {
    word: {
      type: Object,
      required: true,
    },
  },

  methods: {
    short() {
      if (this.word.short !== null && this.word.short.length > 0) {
        return this.word.short;
      }
      return i18n.t('missing-short-message');
    },

    clicked(arg) {
      this.$emit('filter-set', arg);
    },

    tagClicked(tag) {
      this.$emit('filter-set', `tag:"${tag}"`);
    },
  },
};
</script>
