<template>
  <div id="theme-chooser">
    <label for="theme">{{ label }}</label>

    <select
      id="theme"
      v-model="selected"
      @change="change"
    >
      <option
        v-for="item in options"
        :key="item[1]"
        :value="item[1]"
        :selected="item[1] == selected"
      >
        {{ item[0] }}
      </option>
    </select>
  </div>
</template>

<script>
import axios from 'axios';
import csrf from '../utils/csrf';
import flash from '../utils/flash';

export default {
  props: {
    current: {
      type: String,
      required: true,
    },
    themes: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selected: this.$props.current,
    };
  },

  computed: {
    options() {
      return JSON.parse(this.themes);
    },
  },

  methods: {
    change() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token();

      axios({
        method: 'PATCH',
        url: '/settings/theme',
        data: { theme: this.selected },
      }).then((resp) => {
        if (resp.data === '') {
          window.location.reload();
        } else {
          flash.error(resp.data.error);
        }
      }).catch(() => {
        flash.error('Internal error');
      });
    },
  },
};
</script>
