import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm';
import Cookies from '../components/cookies.vue';
import Language from '../components/language.vue';
import ThemeChooser from '../components/theme-chooser.vue';
import Uploader from '../components/uploader.vue';
import DeclensionTable from '../components/declension-table.vue';
import ConjugationTable from '../components/conjugation-table.vue';
import Furnace from '../components/furnace.vue';
import Words from '../components/words.vue';

import NewWord from '../pages/new-word';

// Import stylesheets now.
import '../../assets/stylesheets/application.scss';

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('trix');
require('@rails/actiontext');

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#app',
    ignoredElements: ['trix-editor', 'trix-toolbar'],
    components: {
      Cookies,
      Language,
      ThemeChooser,
      Uploader,
      DeclensionTable,
      ConjugationTable,
      Furnace,
      Words,
    },
  });

  NewWord.setup();
});
