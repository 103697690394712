<template>
  <div id="words">
    <div class="flex items-center justify-between sm:mb-4 lg:mb-0 xl:mb-4">
      <div class="flex-wrap w-full relative flex items-stretch md:w-5/6 w-full">
        <div
          id="filter-selector-toggler"
          class="margin-right--1 flex hover:shadow-lg cursor-pointer"
          @click="showFilters = !showFilters"
        >
          <span class="text-gray-800 border border-gray-200 bg-gray-100 content-center rounded rounded-r-none flex text-sm items-center py-2 px-4">{{ i18n.t('show-filters') }}
            <i
              v-if="showFilters"
              class="fas fa-angle-up ml-4"
            />
            <i
              v-else
              class="fas fa-angle-down ml-4"
            />
          </span>
        </div>

        <div class="relative form-control md:w-5/6">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
          <input
            id="filter"
            v-model.trim="text"
            class="focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 py-2 pl-10 rounded rounded-l-none"
            type="text"
            :aria-label="i18n.t('filter-words')"
            :placeholder="i18n.t('filter-words')"
            @keyup.enter="filterAction"
          >
        </div>
      </div>

      <div class="md:w-1/6 text-right">
        <a
          class="ml-2"
          href="/tags"
        ><button class="btn btn-basic">{{ i18n.t('tags-text') }}</button></a>
        <a
          class="ml-2"
          href="/words/new"
        ><button class="btn btn-new">{{ i18n.t('new-word-text') }}</button></a>
      </div>
    </div>

    <div v-if="clearAvailable">
      <i
        class="far fa-window-close cursor-pointer"
        @click="clearFilter"
      />
      <span
        id="clear-filter-text"
        class="mt-10 mb-10 sm:text-base font-semibold text-gray-900 cursor-pointer hover:underline"
        @click="clearFilter"
      >{{ i18n.t('clear-filter') }}</span>
    </div>

    <div v-if="showFilters">
      <select
        v-model="genderSelected"
        class="rounded border border-gray-400"
        @change="genderChange"
      >
        <option
          disabled
          value=""
        >
          {{ filters['strings']['genders'] }}
        </option>
        <option
          v-for="gender in filters['genders']"
          :value="gender[1]"
        >
          {{ gender[0] }}
        </option>
      </select>

      <select
        id="category-selector"
        v-model="categorySelected"
        class="rounded border border-gray-400"
        @change="categoryChange"
      >
        <option
          disabled
          value=""
        >
          {{ filters['strings']['categories'] }}
        </option>
        <option
          v-for="category in filters['categories']"
          :value="category[1]"
        >
          {{ category[0] }}
        </option>
      </select>

      <select
        v-model="declensionSelected"
        class="rounded border border-gray-400"
        @change="declensionChange"
      >
        <option
          disabled
          value=""
        >
          {{ filters['strings']['declensions'] }}
        </option>
        <option
          v-for="decl in filters['declensions']"
          :value="decl[1]"
        >
          {{ decl[0] }}
        </option>
      </select>

      <select
        v-model="conjugationSelected"
        class="rounded border border-gray-400"
        @change="conjugationChange"
      >
        <option
          disabled
          value=""
        >
          {{ filters['strings']['conjugations'] }}
        </option>
        <option
          v-for="conj in filters['conjugations']"
          :value="conj[1]"
        >
          {{ conj[0] }}
        </option>
      </select>
    </div>

    <div
      v-if="requesting"
      class="m-auto text-center mt-10 mb-10 text-lg sm:text-base font-semibold text-gray-900"
    >
      <wait />
    </div>

    <div
      v-else-if="words.length == 0"
      class="m-auto text-center mt-10 mb-10 text-lg sm:text-base font-semibold text-gray-900"
    >
      Empty!
    </div>

    <div
      v-else
      id="words-grid"
      class="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4"
    >
      <word-card
        v-for="word in words"
        :key="word.id"
        :word="word"
        @filter-set="filterSet"
      />
    </div>

    <div class="flex content-center justify-center my-5">
      <button
        id="prev-button"
        class="btn btn-basic ml-2 disabled:opacity-50"
        :disabled="prev === null || prev < 0 || requesting"
        @click="prevPage"
      >
        {{ i18n.t('prev-text') }}
      </button>

      <button
        id="next-button"
        class="btn btn-basic ml-2 disabled:opacity-50"
        :disabled="next === null || requesting"
        @click="nextPage"
      >
        {{ i18n.t('next-text') }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import csrf from '../utils/csrf';
import flash from '../utils/flash';
import i18n from '../utils/i18n';
import WordCard from './word-card.vue';
import Wait from './wait.vue';

// Defined in `lib/mihi/filter.rb` in the `ROWS_PER_PAGE` variable.
const ITEMS_PER_PAGE = 20;

export default {
  components: {
    WordCard,
    Wait,
  },

  data() {
    return {
      text: '',
      lastText: '',
      requesting: false,
      clearAvailable: false,
      showFilters: false,
      filters: null,
      prev: null,
      next: null,
      words: [],
      i18n,
      genderSelected: '',
      categorySelected: '',
      declensionSelected: '',
      conjugationSelected: '',
    };
  },

  created() {
    const opts = document.getElementById('filter-options').getAttribute('data').trim();
    this.filters = JSON.parse(opts);
  },

  mounted() {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token();

    this.fetchWords();
  },

  methods: {
    fetchWords() {
      if (this.requesting) {
        return;
      }
      this.requesting = true;

      const params = { filter: this.text };
      if (this.lastText === this.text && this.next !== null) {
        this.prev = null;
        params.offset = this.next;
      }

      this.lastText = this.text;

      axios({
        method: 'GET',
        url: '/words.json',
        params,
      }).then((resp) => {
        this.prev = resp.data.next - (2 * ITEMS_PER_PAGE);
        this.next = resp.data.next;
        this.words = resp.data.words;
        this.requesting = false;

        if (this.text.length > 0) {
          this.clearAvailable = true;
        }
      }).catch(() => {
        this.requesting = false;
        flash.error('Internal error');
      });
    },

    filterAction() {
      this.words = [];
      this.fetchWords();
    },

    // Adds the given argument `arg` as a new filter and calls on the server.
    filterSet(arg) {
      // Remove previous filters if we are overwriting it.
      if (arg.indexOf('tag:') !== -1) {
        this.text = this.text.replace(/tag:".+"/, '');
      } else if (arg.indexOf('gen:') !== -1) {
        this.text = this.text.replace(/gen:\d+/, '');
        this.genderSelected = arg;
      } else if (arg.indexOf('cat:') !== -1) {
        this.text = this.text.replace(/cat:\w+/, '');
        this.categorySelected = arg;
      } else if (arg.indexOf('decl:') !== -1) {
        this.text = this.text.replace(/decl:\d+/, '');
        this.declensionSelected = arg;
      } else if (arg.indexOf('conj:') !== -1) {
        this.text = this.text.replace(/conj:\d+/, '');
        this.conjugationSelected = arg;
      }

      // Append the filter and fetch words.
      this.appendToText(arg);
      this.fetchWords();
    },

    clearFilter() {
      // Information on the text itself.
      this.text = '';
      this.lastText = '';

      // Filter itself.
      this.clearAvailable = false;
      this.genderSelected = '';
      this.categorySelected = '';
      this.declensionSelected = '';
      this.conjugationSelected = '';

      // Pagination.
      this.prev = null;
      this.next = null;

      this.fetchWords();
    },

    genderChange() {
      if (this.text.indexOf('gen:') !== -1) {
        this.text = this.text.replace(/gen:\d+/, '');
      }
      this.appendToText(this.genderSelected);
      this.fetchWords();
    },

    categoryChange() {
      if (this.text.indexOf('cat:') !== -1) {
        this.text = this.text.replace(/cat:\w+/, '');
      }
      this.appendToText(this.categorySelected);
      this.fetchWords();
    },

    declensionChange() {
      if (this.text.indexOf('decl:') !== -1) {
        this.text = this.text.replace(/decl:\d+/, '');
      }
      this.appendToText(this.declensionSelected);
      this.fetchWords();
    },

    conjugationChange() {
      if (this.text.indexOf('conj:') !== -1) {
        this.text = this.text.replace(/conj:\d+/, '');
      }
      this.appendToText(this.conjugationSelected);
      this.fetchWords();
    },

    appendToText(str) {
      if (this.text !== '') {
        this.text += ' ';
      }
      this.text += str;
    },

    prevPage() {
      this.next = this.prev;
      this.fetchWords();
    },

    nextPage() {
      this.prev = this.next;
      this.fetchWords();
    },
  },
};
</script>

<style>
.margin-right--1 {
  margin-right: -1px;
}
</style>
