const show = (message, klass) => {
  const el = `<div class="flash flash-${klass}">${message}</div>`;
  document.getElementById('flash').innerHTML = el;
};

const error = (message) => {
  show(message, 'error');
};

const info = (message) => {
  show(message, 'info');
};

export default {
  error,
  info,
};
