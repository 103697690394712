<template>
  <div class="conjugation-table">
    <table>
      <tr>
        <th v-for="h in safeHead">
          {{ h }}
        </th>
      </tr>

      <!-- TODO: generalize!! -->
      <tr v-if="shouldBeShown(1)">
        <!-- TODO: rowspan="number of person categories" -->
        <td rowspan="3">
          Singular (i18n)
        </td>
        <td>
          1a (i18n)
        </td>
        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 1, 0)"
        />
      </tr>

      <tr>
        <td v-if="!shouldBeShown(1) && !shouldBeShown(3)">
          Singular (i18n)
        </td>

        <td>2a</td>

        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 2, 0)"
        />
      </tr>

      <tr v-if="shouldBeShown(3)">
        <td>
          3a
        </td>
        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 3, 0)"
        />
      </tr>

      <tr v-if="shouldBeShown(1)">
        <!-- TODO: rowspan="number of person categories" -->
        <td rowspan="3">
          Plural (i18n)
        </td>
        <td>
          1a (i18n)
        </td>
        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 1, 1)"
        />
      </tr>

      <tr>
        <td v-if="!shouldBeShown(1) && !shouldBeShown(3)">
          Plural (i18n)
        </td>

        <td>2a</td>

        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 2, 1)"
        />
      </tr>
      <tr v-if="shouldBeShown(3)">
        <td>
          3a
        </td>
        <td
          v-for="(conj, index) in safeConjugations"
          v-html="inflect(safeWords[index], conj, 3, 1)"
        />
      </tr>
    </table>
  </div>
</template>

<script>
import Inflector from '../utils/inflector';

export default {
  props: {
    head: {
      type: String,
      required: true,
    },
    conjugations: {
      type: String,
      required: true,
    },
    tense: {
      type: String,
      required: true,
    },
    words: {
      type: String,
      required: true,
    },
    mood: {
      type: Number,
      required: true,
    },
    voice: {
      type: String,
      required: true,
    },
    forms: {
      type: String,
      required: true,
    },
  },

  computed: {
    safeHead() {
      return JSON.parse(this.head);
    },
    safeConjugations() {
      return JSON.parse(this.conjugations);
    },
    safeWords() {
      return JSON.parse(this.words);
    },
  },

  created() {
    Inflector.addForms(JSON.parse(this.forms));
  },

  methods: {
    inflect(word, conj, person, number) {
      if (this.$props.mood === Inflector.IMPERATIVE) {
        if (person !== 2) {
          return '';
        }
      }
      return Inflector.conjugate(
        word, conj,
        this.voice, this.mood, this.tense, person, number,
        { markTerm: true },
      );
    },
    shouldBeShown(person) {
      if (this.$props.mood === Inflector.IMPERATIVE) {
        return person === 2;
      }
      return true;
    },
  },
};
</script>
