<!-- Separate into different components. This is a mess! -->
<!-- TODO: See #11, also for eslint -->
<template>
  <div id="furnace">
    <div id="timer">
      <p
        v-if="timer >= 0"
        v-html="timer"
      />
    </div>

    <!-- eslint-disable vue/no-use-v-if-with-v-for -->
    <div
      v-for="(question, idx) in safeQuestions()"
      v-if="idx == question_index"
      class="question"
    >
      <input
        id="timer-value"
        name="timer-value"
        type="hidden"
        :value="`${question.time}`"
      >

      <div v-if="question.type == 'word'">
        <table>
          <p v-html="question.title" />
          <tr>
            <td>Enunciate:</td>
            <td>
              <input
                type="text"
                class="question-input enunciated"
                :name="`${question.word.enunciated}`"
                autofocus="autofocus"
                @keyup.enter="buttonClick"
              >
            </td>
          </tr>
          <tr>
            <td>{{ question.name }}</td>
            <td>
              <input
                type="text"
                class="question-input word"
                :name="`${inflectOrConjugate(question)}`"
                @keyup.enter="buttonClick"
              >
            </td>
          </tr>
        </table>
      </div>

      <div v-else-if="question.type == 'exercise'">
        <exercise
          :id="`${idx}`"
          @enterpressed="buttonClick"
        />
      </div>

      <div v-else-if="question.word.kind == 'verb'">
        <p><b>{{ question.word.enunciated }}</b></p>

        <table>
          <tr>
            <th>Number</th>
            <th>Person (i18n)</th>
            <th
              v-for="tense in question.tenses"
              v-html="tense.name"
            />
          </tr>

          <tr
            v-for="index in 6"
            :key="index"
          >
            <td
              v-if="index == 1"
              rowspan="3"
            >
              Singular
            </td>
            <td
              v-else-if="index == 4"
              rowspan="3"
            >
              Plural
            </td>

            <td v-if="index < 4">
              {{ index }}
            </td>
            <td v-else>
              {{ index - 3 }}
            </td>

            <template v-for="tense in question.tenses">
              <td>
                <input
                  class="question-input"
                  :name="`${conjugate(question.word, tense.key, index)}`"
                  autofocus="autofocus"
                  @keyup.enter="buttonClick"
                >
              </td>
            </template>
          </tr>
        </table>
      </div>

      <div v-else>
        <p>(i18n) Yo, decline this shit {{ question.category }}: <b>{{ enunciateQuestionFor(question.word) }}</b> {{ fetchGender(question.word, question.gender) }}</p>

        <table>
          <tr>
            <th>{{ casetext }}</th>
            <th>{{ singulartext }}</th>
            <th v-if="hasPlural(question.word)">
              {{ pluraltext }}
            </th>
          </tr>

          <tr v-for="(c, index) in question.cases">
            <td>{{ c.name }}</td>
            <td v-if="index == 0">
              <input
                type="text"
                class="question-input singular"
                :name="`${inflect(question.word, c.casus, singularValue, question.gender)}`"
                autofocus="autofocus"
                @keyup.enter="buttonClick"
              >
            </td>
            <td v-if="index != 0">
              <input
                type="text"
                class="question-input singular"
                :name="`${inflect(question.word, c.casus, singularValue, question.gender)}`"
                @keyup.enter="buttonClick"
              >
            </td>
            <td v-if="hasPlural(question.word)">
              <input
                type="text"
                class="question-input plural"
                :name="`${inflect(question.word, c.casus, pluralValue, question.gender)}`"
                @keyup.enter="buttonClick"
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- eslint-enable vue/no-use-v-if-with-v-for -->

    <button
      v-if="timer > 0"
      @click="pauseClick"
    >
      Stop (i18n)
    </button>
    <button @click="buttonClick">
      {{ check }}
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import Inflector from '../utils/inflector';
import Exercise from './exercise.vue';
import csrf from '../utils/csrf';
import flash from '../utils/flash';

export default {

  components: {
    Exercise,
  },
  props: {
    questions: {
      type: String,
      required: true,
    },
    casetext: {
      type: String,
      required: true,
    },
    singulartext: {
      type: String,
      required: true,
    },
    pluraltext: {
      type: String,
      required: true,
    },
    masculinetext: {
      type: String,
      required: true,
    },
    femininetext: {
      type: String,
      required: true,
    },
    neutertext: {
      type: String,
      required: true,
    },
    intext: {
      type: String,
      required: true,
    },
    contentelementid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      question_index: 0,
      checked: false,
      check: 'Check (i18n)',
      timer: -1,
      timerToBeUpdated: true,
      shouldFocus: true,
      currentQuestion: 0,
    };
  },

  computed: {
    singularValue() {
      return Inflector.SINGULAR;
    },
    pluralValue() {
      return Inflector.PLURAL;
    },
  },

  created() {
    this.safeQuestions().forEach((el) => {
      Inflector.addForms(el.forms);
    });
  },

  updated() {
    // When a new question is passed, focus the first user input element.
    if (!this.checked) {
      if (this.shouldFocus) {
        const el = this.$el.querySelectorAll('.question-input');
        if (el.length > 0) {
          el[0].focus();
        }
      }
      this.shouldFocus = false;

      if (this.timerToBeUpdated) {
        this.timerToBeUpdated = false;
        this.updateTimer();
      }
    }
  },

  mounted() {
    this.updateTimer();
  },

  methods: {
    safeQuestions() {
      return JSON.parse(this.questions);
    },

    buttonClick() {
      if (this.checked) {
        this.nextAnswer();
      } else {
        this.checkAnswer();
      }
    },

    hasPlural(word) {
      if (word.kind === 'interrogative') {
        return false;
      }
      return word.defective !== Inflector.ONLY_SINGULAR;
    },

    checkAnswer() {
      const question = this.safeQuestions()[this.question_index];
      const results = {
        ok: 0, fail: 0, failures: [], successes: [],
      };

      this.$el.querySelectorAll('.question-input').forEach((el) => {
        let res = el.name.trim();
        let correct;
        let id = null;

        if (question.type === 'exercise') {
          correct = res.replace(/\s+/g, '');
        } else {
          res = res.split('-');
          if (res.length > 1) {
            correct = res[1].replace(/\s+/g, '');
            id = parseInt(res[0], 10);
          } else {
            correct = res[0].replace(/\s+/g, '');
          }
        }

        const given = el.value.trim().replace(/\s+/g, '');

        if (correct === given) {
          results.ok += 1;
          if (id !== null) {
            results.successes.push(id);
          }

          el.classList.add('question-right');
        } else {
          results.fail += 1;
          if (id !== null) {
            results.failures.push(id);
          }

          el.classList.add('question-wrong');
          el.insertAdjacentHTML('afterend', `<span class="correction"">${correct}</span>`);
          // TODO: some cool effect
        }
      });

      // TODO: submit stats coordinating with nextAnswer
      this.postStats(question, results);

      this.questionContentVisible(true);

      this.check = 'Next (i18n)';
      this.checked = true;
      this.timer = -1;
    },

    postStats(question, results) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf.token();

      if (question.type === 'exercise') {
        this.postExerciseStats(question.exercise.id, results);
      } else {
        this.postWordStats(question.word.id, results);
      }
    },

    postWordStats(id, results) {
      const succeeded = results.fail > 0 ? 0 : 1;

      axios({
        method: 'POST',
        url: `/words/${id}/stats`,
        data: { succeeded, successes: results.successes, failures: results.failures },
      }).catch(() => {
        flash.error('Internal error');
      });
    },

    postExerciseStats(id, results) {
      const succeeded = results.fail > 0 ? 0 : 1;

      axios({
        method: 'POST',
        url: `/exercises/${id}/stats`,
        data: { succeeded },
      }).catch(() => {
        flash.error('Internal error');
      });
    },

    fetchGender(word, gender) {
      switch (Inflector.reflectGender(word, gender)) {
        case 0:
          return `(${this.intext} ${this.masculinetext.toLowerCase()})`;
        case 1:
          return `(${this.intext} ${this.femininetext.toLowerCase()})`;
        case 2:
          return `(${this.intext} ${this.neutertext.toLowerCase()})`;
        default:
          return '';
      }
    },

    enunciateQuestionFor(word) {
      if (word.category === Inflector.PRONOUN) {
        return word.enunciated;
      } if (word.category === Inflector.NOUN) {
        return `${word.particle}- (${word.kind})`;
      }
      return word.particle;
    },

    number(bool) {
      // TODO: i18n
      if (bool) {
        return 'Plural';
      }
      return 'Singular';
    },

    conjugate(word, key, index) {
      let person = index % 3;
      if (person === 0) {
        person = 3;
      }

      const number = (index > 3) ? Inflector.PLURAL : Inflector.SINGULAR;

      return Inflector.conjugateWithKey(word, key, person, number, { addTermId: true });
    },

    inflect(word, casus, number, gender) {
      return Inflector.inflect(word, casus, number, { gender, addTermId: true });
    },

    inflectOrConjugate(question) {
      if (question.word.category === Inflector.VERB) {
        return Inflector.conjugateWithForm(question.word, question.form, { addTermId: true });
      }
      return this.inflect(question.word, question.casus, question.form.number, question.gender);
    },

    questionContentVisible(visible) {
      const el = document.getElementById(`${this.contentelementid}-${this.currentQuestion}`);

      if (visible) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    },

    nextAnswer() {
      this.questionContentVisible(false);
      this.currentQuestion += 1;

      this.checked = false;
      this.question_index += 1;
      if (this.question_index === 10) {
        this.check = 'Review (i18n)';
        // TODO: instead of this, just show all of them or some sort of results
      } else {
        this.shouldFocus = true;
        this.timerToBeUpdated = true;
        this.check = 'Check (i18n)';
      }
      // TODO: submit stats
    },

    pauseClick() {
      this.timer = -1;
      this.buttonClick();
    },

    updateTimer() {
      if (this.timer < 0) {
        if (typeof (this.$el.querySelector) !== 'undefined') {
          this.timer = parseInt(this.$el.querySelector('#timer-value').value, 10);
          if (this.timer >= 0) {
            this.updateTimer();
          }
        }
      } else if (this.timer > 0) {
        setTimeout(() => {
          if (this.timer >= 0) {
            this.timer -= 1;
            this.timerToBeUpdated = false;
            this.updateTimer();
          }
        }, 1000);
      } else {
        this.buttonClick();
      }
    },
  },
};
</script>

<style>
  .question-right {
    border: 2px solid green;
    border-radius: 4px;
  }

  .question-wrong {
    border: 2px solid red;
    border-radius: 4px;
  }
</style>
