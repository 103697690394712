// TODO: do clever stuff like guessing the root or the enunciated

// Parsed data from the JSON input. Initialized by the `setup` function.
let data;

// Update the kind <select> tag with the desired options.
const updateKindSelect = () => {
  const { value } = document.querySelector('#word_declension_id');
  const options = data[value];
  const select = document.querySelector('#word_kind');

  select.innerHTML = '';
  select.disabled = false;

  options.forEach((opt) => {
    const el = document.createElement('option');
    el.textContent = opt;
    el.value = opt;
    select.appendChild(el);
  });
};

// Setup all the needed JS for the /words/new page.
const setup = () => {
  // If this is not the desired page or the JSON data is not really there for
  // whatever reason, leave early. The select HTML will be kept as disabled on
  // error, with a default value which is to be ignored by the controller.
  const raw = document.querySelector('#kinds');
  if (typeof (raw) === 'undefined' || raw === null) {
    return;
  }
  if (typeof (raw.value) === 'undefined') {
    return;
  }

  // Initialize the kind selector.
  data = JSON.parse(raw.value);
  updateKindSelect();

  // It's better to have this event listener addition here than in the view
  // because adding JS module's functions might be actually uglier on the view.
  const select = document.querySelector('#word_declension_id');
  select.addEventListener('change', updateKindSelect, false);
};

export default {
  setup,
  updateKindSelect,
};
