<template>
  <div
    class="exercise"
    v-html="renderMyself"
  />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    renderMyself() {
      const el = document.getElementById(`exercise-element-cover-${this.$props.id}`);
      const tpl = '<input type="text" class="question-input" name="$1"></input>';

      el.innerHTML = el.innerHTML.replaceAll(/__(\w+)__/g, tpl);

      return el.innerHTML;
    },
  },

  mounted() {
    this.$el.querySelectorAll('.question-input').forEach((el, idx) => {
      if (idx === 0) {
        el.focus();
      }
      el.addEventListener('keyup', this.keyupEvent);
    });
  },

  beforeUnmount() {
    this.$el.querySelectorAll('.question-input').forEach((el) => {
      el.removeEventListener('keyup', this.keyupEvent);
    });
  },

  methods: {
    keyupEvent($event) {
      if ($event.keyCode === 13) {
        $event.preventDefault();
        this.$emit('enterpressed');
      }
    },
  },
};
</script>
