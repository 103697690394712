<template>
  <div class="declension-table">
    <select
      v-if="safeWords.length > 0"
      v-model="selected"
      class="word-selection"
    >
      <option
        v-if="hasRegularWords"
        value="-1"
      >
        {{ justendingstext }}
      </option>
      <option
        v-for="(word, idx) in safeWords"
        :value="idx"
        :selected="idx == selected"
      >
        {{ word.enunciated }}
      </option>
    </select>

    <table>
      <tr>
        <th>{{ casetext }}</th>
        <th v-if="hasSingular()">
          {{ singulartext }}
        </th>
        <th v-if="hasPlural()">
          {{ pluraltext }}
        </th>
      </tr>

      <tr v-for="o in safeOrder()">
        <td>{{ o[1] }}</td>
        <td
          v-if="hasSingular()"
          v-html="inflect(parseInt(o[0]), singularValue)"
        />
        <td
          v-if="hasPlural()"
          v-html="inflect(parseInt(o[0]), pluralValue)"
        />
      </tr>
    </table>
  </div>
</template>

<script>
import Inflector from '../utils/inflector';

export default {
  props: {
    words: {
      type: String,
      required: true,
    },
    forms: {
      type: String,
      required: true,
    },
    order: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
    casetext: {
      type: String,
      required: true,
    },
    singulartext: {
      type: String,
      required: true,
    },
    pluraltext: {
      type: String,
      required: true,
    },
    justendingstext: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selected: -1,
    };
  },

  computed: {
    safeWords() {
      return JSON.parse(this.words);
    },
    singularValue() {
      return Inflector.SINGULAR;
    },
    pluralValue() {
      return Inflector.PLURAL;
    },
    isPronoun() {
      return this.kind === 'interrogative' || this.kind === 'relative'
        || this.kind === 'personal' || this.kind === 'demonstrative-weak'
        || this.kind === 'demonstrative-proximal' || this.kind === 'demonstrative-distal'
        || this.kind === 'demonstrative-medial' || this.kind === 'idem'
        || this.kind === 'intensive';
    },
    hasRegularWords() {
      return this.safeWords.some((el) => el.regular);
    },
  },

  created() {
    Inflector.addForms(JSON.parse(this.forms));

    if (!this.hasRegularWords && !this.isPronoun) {
      this.selected = 0;
    }
  },

  methods: {
    safeOrder() {
      let items = JSON.parse(this.order);

      items = items.filter((x) => {
        // Handle pronouns now.
        if (this.isPronoun) {
          if (x[0] === Inflector.VOCATIVE || x[0] === Inflector.LOCATIVE) {
            return false;
          }
          if (x[0] === Inflector.NOMINATIVE && this.index === 'personal-3') {
            return false;
          }
          return true;
        }

        // The locative case has to be filtered out if: 1. We are showing
        // just the case endings (i.e. this.selected < 0); 2. The selected
        // word does not have a locative case.
        if (x[0] !== Inflector.LOCATIVE) {
          return true;
        }
        if (this.selected < 0) {
          return false;
        }
        return this.safeWords[this.selected].locative;
      });
      return items;
    },

    inflect(casus, number) {
      const opts = { markTerm: this.selected >= 0 && !this.isPronoun };

      let word;
      if (this.selected < 0) {
        word = { particle: '-', kind: this.index };
      } else {
        word = this.safeWords[this.selected];
      }

      if (this.isPronoun) {
        word.category = Inflector.PRONOUN;
      }

      return Inflector.inflect(word, casus, number, opts);
    },

    hasSingular() {
      if (this.selected < 0) {
        return true;
      }
      return this.safeWords[this.selected].defective !== Inflector.ONLY_PLURAL;
    },

    hasPlural() {
      if (this.selected < 0) {
        return this.kind !== 'interrogative';
      }
      return this.safeWords[this.selected].defective !== Inflector.ONLY_SINGULAR;
    },
  },
};
</script>
